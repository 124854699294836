import api from "@/api/api";

class CompaniesApi {
  constructor() {
    this.api = api;
  }

  getCompaniesByFilters(filter) {
    return this.api.post("companies/filter/", filter);
  }

  getAll() {
    return this.api.get("companies/");
  }

  getCompany(id) {
    return this.api.get(`companies/${id}/`);
  }

  edit(id, appName, isUnitedBuild = false, appNameEn, company) {
    return this.api.post(`companies/${id}/`, {
      ...company,
      app_name: appName,
      is_united_build: isUnitedBuild,
      app_name_en: appNameEn,
    });
  }

  approveDesign(id, platform, approve) {
    return this.api.post(`companies/${id}/approve-design`, {
      platform: platform,
      approve: approve,
    });
  }

  resetDataZip(id) {
    return this.api.get(`companies/${id}/reset-data-zip`);
  }

  resetFigmaCache(id, platform) {
    return this.api.post(`companies/${id}/reset-figma-cache`, {
      platform: platform,
    });
  }

  editImage(id, image) {
    return this.api.postFile(`companies/${id}/image`, "image", image, [
      { name: "android", value: true },
    ]);
  }

  archive(id) {
    return this.api.post(`companies/${id}/archive/`);
  }

  unarchive(id) {
    return this.api.post(`companies/${id}/unarchive/`);
  }

  refresh() {
    return this.api.post("companies/refresh/");
  }

  add(name, companyId, serverId) {
    return this.api.post("companies/", {
      name,
      company_id: companyId,
      server_id: serverId,
    });
  }

  error() {
    return this.api.post("companies/error/");
  }

  export() {
    return this.api.download("export/companies/");
  }

  getFigmaHistory(id) {
    return this.api.get(`companies/${id}/figma-history/`);
  }

  getDesignStatus(id) {
    return this.api.get(`companies/${id}/design-status/`);
  }

  getCompanyReleases(id, page) {
    return this.api.get(`companies/${id}/releases/?page=${page}`);
  }

  getLastVersion(id) {
    return this.api.get(`companies/${id}/last-version/`);
  }
}

export default new CompaniesApi();
