<template>
  <div>
    <label class="label">Добавить приложения по тегам:</label>
    <b-field grouped group-multiline>
      <div class="control" v-for="tag in tags" :key="tag.id">
        <b-taglist attached>
          <b-tag :type="'is-' + tag.color">{{ tag.title }}</b-tag>

          <b-tag>
            <a @click="addCompaniesByTag(tag)">
              <b-icon icon="plus" size="is-small"></b-icon>
            </a>
          </b-tag>
        </b-taglist>
      </div>
    </b-field>

    <p>Компаний выбрано: {{ selectedCompanies.length }}</p>
    <b-table
      v-if="allCompanies"
      :data="filterCompanies"
      :checked-rows.sync="selectedCompanies"
      :custom-is-checked="(a, b) => a.id === b.id"
      :is-row-checkable="canCheckCompany"
      :total="totalCompanies"
      :currentPage.sync="syncCurrentPage"
      :per-page="50"
      checkable
      paginated
      backend-pagination
      backend-filtering
      @page-change="onPageChange"
      @filters-change="onFilter"
    >
      <b-table-column 
        label="Название сервера" 
        v-slot="props" 
        searchable 
        field="server_id"
      >
        {{ getServerName(props.row.server_id) }}
      </b-table-column>
      <b-table-column
        label="ID"
        searchable
        field="company_id"
        width="6rem"
        v-slot="props"
      >
        <div>{{ props.row.company_id }}</div>
      </b-table-column>

      <b-table-column
        label="Название компании"
        searchable
        field="name"
        v-slot="props"
      >
        <div>
          {{
            props.row.name.length > 30
              ? props.row.name.slice(0, 30) + "..."
              : props.row.name
          }}
        </div>

        <div style="margin-bottom: 6px">
          <b-tag
            type="is-danger"
            v-if="
              props.row.validation_errors &&
              props.row.validation_errors.android.length > 0
            "
          >
            <b-icon icon="cancel" size="is-small"></b-icon>
            <span>Android</span>
          </b-tag>
        </div>

        <b-taglist>
          <b-tag
            v-for="tag in props.row.tags"
            :key="tag.id"
            :type="'is-' + tag.color"
          >
            {{ tag.title }}
          </b-tag>
        </b-taglist>
      </b-table-column>

      <b-table-column
        label="Название приложения"
        searchable
        field="app_name"
        v-slot="props"
      >
        <p>{{ props.row.app_name }}</p>
        <p>{{ props.row.app_name_android }}</p>
        <p>{{ props.row.app_name_ios }}</p>
      </b-table-column>

      <b-table-column label="Последняя версия" v-slot="props">
        <span>
          <p v-if="props.row.android_last_version">
            <b-icon icon="android" type="is-success"></b-icon>
            {{ props.row.android_last_version }}
          </p>
        </span>
      </b-table-column>
    </b-table>

    <div v-else>
      <section>
        <div class="container">
          <div class="columns">
            <div class="column has-text-centered">
              <p class="has-text-grey">Компаний еще нет</p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <p>Компаний выбрано: {{ selectedCompanies.length }}</p>
  </div>
</template>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
    totalCompanies: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      selectedCompanies: [],
      searchShowArchive: false,
    };
  },
  computed: {
    ...mapState({
      allCompanies: (state) => state.companies.available,
      tags: (state) => state.tags.available,
      servers: (state) => state.servers.available,
    }),

    syncCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("update:currentPage", val);
      },
    },

    filterCompanies: function () {
      return this.allCompanies.filter((c) => {
        if (!this.searchShowArchive && c.is_archived) {
          return false;
        }
        return true;
      });
    },
  },
  watch: {
    selectedCompanies: function () {
      this.$emit("update:companies", this.selectedCompanies);
    },
  },
  mounted: function () {
    this.selectedCompanies = [];
    for (var company of this.companies) {
      this.selectedCompanies.push(
        this.allCompanies.find((c) => c.id === company.id)
      );
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    ...mapActions("companies", {
      getCompaniesByTagIds: "getCompaniesByTagIds",
    }),

    onFilter: _.debounce(function (filters) {
      this.$emit("onFilter", filters);
    }, 500),

    canCheckCompany: function (company) {
      return true;
    },

    addCompaniesByTag: function (tag) {
      this.startLoading();
      this.getCompaniesByTagIds({ tags_ids: [tag.id], per_page: 5000 })
        .then((res) => {
          const arr = this.selectedCompanies.slice();
          for (const company of res.companies) {
            if (
              company.tags.some((t) => t.id === tag.id) &&
              !company.is_archive
            ) {
              if (!this.selectedCompanies.find((c) => c.id === company.id)) {
                arr.push(company);
              }
            }
          }
          this.selectedCompanies = arr.slice();
          this.stopLoading();
        })
        .catch((error) => {
          this.$handleError(error);
          this.stopLoading();
        });
    },
    onPageChange() {
      this.$emit("onPageChange");
    },
    getServerName(serverId) {
      for (const server of this.servers) {
        if (server.id == Number.parseInt(serverId)) {
          return server.name;
        }
      }
      return serverId;
    },
  },
};
</script>
