import Vue from "vue";

import CompaniesApi from "@/api/companies";

const state = {
  available: null,
  byServer: {},
  currentCompanyReleases: [],
  company: null,
  versions: {},
};

const getters = {
  getByServer: (state) => (id) => {
    if (id in state.byServer) {
      return state.byServer[id];
    }
    return [];
  },
  getAllCompanies: (state) => {
    return state.available;
  },
};

const mutations = {
  storeCompanies(state, companies) {
    state.available = companies;
    state.available.sort((a, b) => a.company_id - b.company_id);

    state.byServer = refreshServers(state.available);
  },
  storeCompaniesAppend(state, companies) {
    if (state.available && state.available.length) {
      state.available.push(...companies);
      state.available.sort((a, b) => a.company_id - b.company_id);
    } else {
      state.available = companies;
    }
    state.byServer = refreshServers(state.available);
  },
  storeCompany(state, company) {
    state.company = company;
  },
  addCompany(state, company) {
    state.company = company;
  },
  clearCompanies(state) {
    state.byServer = {};
    state.available = null;
  },

  editCompany(state, company) {
    state.available = state.available.map((c) => {
      if (c.id === company.id) {
        return company;
      }
      return c;
    });

    state.byServer = refreshServers(state.available);
  },
  editCurrentCompany(state, company) {
    state.company = company;
  },

  refreshDesignStatus(state, { id, newStatus }) {
    state.available = state.available.map((c) => {
      if (c.id === id) {
        c.android_approved_design = newStatus.android.approved;
        c.android_approved_design_version = newStatus.android.version;
        c.ios_approved_design = newStatus.ios.approved;
        c.ios_approved_design_version = newStatus.ios.version;
      }
      return c;
    });
  },
  refreshDesignStatusForCurrentCompany(state, { newStatus }) {
    state.company.android_approved_design = newStatus.android.approved;
    state.company.android_approved_design_version = newStatus.android.version;
    state.company.ios_approved_design = newStatus.ios.approved;
    state.company.ios_approved_design_version = newStatus.ios.version;
  },

  storeCompanyReleases(state, { releases }) {
    state.currentCompanyReleases = releases;
  },

  saveVersion(state, { id, version }) {
    Vue.set(state.versions, id, version);
  },
};
const actions = {
  clearCompanies({ commit }) {
    commit("clearCompanies");
  },

  getCompaniesByTagIds({ commit }, filters) {
    return CompaniesApi.getCompaniesByFilters(filters).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }
      return Promise.resolve(res.data);
    });
  },

  getCompaniesByFilters({ commit }, filters) {
    return CompaniesApi.getCompaniesByFilters(filters).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeCompanies", res.data.companies);
      return Promise.resolve(res.data);
    });
  },
  appendCompanies({ commit }, filters) {
    return CompaniesApi.getCompaniesByFilters(filters).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeCompaniesAppend", res.data.companies);
      return Promise.resolve();
    });
  },

  getAll({ commit }) {
    return CompaniesApi.getAll().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeCompanies", res.data.companies);
      return Promise.resolve();
    });
  },
  getCompany({ commit }, { id }) {
    return CompaniesApi.getCompany(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("storeCompany", res.data.company);
      return Promise.resolve();
    });
  },
  edit({ commit }, { id, appName, isUnitedBuild, appNameEn, company }) {
    return CompaniesApi.edit(id, appName, isUnitedBuild, appNameEn, company).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("editCurrentCompany", res.data.company);

      return Promise.resolve();
    });
  },
  approveDesign({ dispatch }, { id, platform, approve }) {
    return CompaniesApi.approveDesign(id, platform, approve).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return dispatch("getDesignStatusForCurrentCompany", { id });
    });
  },
  resetDataZip({ _ }, { id }) {
    return CompaniesApi.resetDataZip(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
  resetFigmaCache({ _ }, { id, platform }) {
    return CompaniesApi.resetFigmaCache(id, platform).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
  editImage({ commit }, { id, image }) {
    return CompaniesApi.editImage(id, image).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve();
    });
  },
  archive({ commit }, { id }) {
    return CompaniesApi.archive(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("editCurrentCompany", res.data.company);

      return Promise.resolve();
    });
  },
  unarchive({ commit }, { id }) {
    return CompaniesApi.unarchive(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("editCurrentCompany", res.data.company);

      return Promise.resolve();
    });
  },
  refresh({ dispatch }) {
    return CompaniesApi.refresh().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return dispatch("getAll");
    });
  },
  add({ commit, dispatch }, { name, companyId, serverId }) {
    return CompaniesApi.add(name, companyId, serverId).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("addCompany", res.data.company);

      return dispatch("getAll");
    });
  },
  markCompanyWithTag({ state, commit }, { companyId, tag }) {
    var company = state.company;
    company.tags.push(tag);
    commit("editCurrentCompany", company);
  },
  unmarkCompanyWithTag({ state, commit }, { companyId, tagId }) {
    var company = state.company;
    company.tags = company.tags.filter((t) => t.id !== tagId);
    commit("editCurrentCompany", company);
  },
  getFigmaHistory(_context, { id }) {
    return CompaniesApi.getFigmaHistory(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve(res.data);
    });
  },
  getDesignStatus({ commit }, { id }) {
    return CompaniesApi.getDesignStatus(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("refreshDesignStatus", { id, newStatus: res.data });

      return Promise.resolve(res.data);
    });
  },
  getDesignStatusForCurrentCompany({ commit }, { id }) {
    return CompaniesApi.getDesignStatus(id).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      commit("refreshDesignStatusForCurrentCompany", {
        id,
        newStatus: res.data,
      });

      return Promise.resolve(res.data);
    });
  },

  getCompanyReleases({ commit }, { id, page }) {
    return CompaniesApi.getCompanyReleases(id, page).then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }
      commit("storeCompanyReleases", { releases: res.data.releases });

      return Promise.resolve(res.data);
    });
  },

  error({ _ }) {
    return CompaniesApi.error().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return res;
    });
  },
  export(_context) {
    return CompaniesApi.export().then((res) => {
      if ("error" in res.data) {
        return Promise.reject(res.data.error);
      }

      return Promise.resolve(res.data);
    });
  },

  async loadVersions({ state, commit }) {
    for (const company of state.available) {
      const res = await CompaniesApi.getLastVersion(company.id);
      commit("saveVersion", { id: company.id, version: res.data.version });
    }
  },
};

function refreshServers(companies) {
  var byServer = {};

  companies.forEach((c) => {
    if (!(c.server_id in byServer)) {
      byServer[c.server_id] = [];
    }
    byServer[c.server_id].push(c);
  });
  return byServer;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
